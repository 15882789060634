@tailwind base;
@tailwind components;
@tailwind utilities;

li > p {
  display: inline;
}

body {
  word-break: break-word;
}
